var menu = {
  code: 200,
  message: null,
  data: [
  // {
  //   id: 171,
  //   pid: 0,
  //   title: '首页',
  //   icon: 's-grid',
  //   perms: '',
  //   path: '/dashboard',
  //   menuType: 'M',
  //   sort: 9999,
  //   children: [],
  // },
  {
    id: 176,
    pid: 0,
    title: '设置',
    icon: 's-tools',
    perms: '',
    path: '/operation',
    menuType: 'M',
    sort: 1880,
    children: [{
      id: 192,
      pid: 176,
      title: '商户基本设置',
      icon: '',
      perms: '',
      path: '/operation/modifyStoreInfo',
      menuType: 'C',
      sort: 10,
      children: []
    }, {
      id: 193,
      pid: 176,
      title: '店铺管理',
      icon: '',
      perms: '',
      path: '/operation/merList',
      menuType: 'C',
      sort: 10,
      children: []
    }
    // {
    //   id: 193,
    //   pid: 176,
    //   title: '品牌管理',
    //   icon: '',
    //   perms: '',
    //   path: '/operation/brand',
    //   menuType: 'C',
    //   sort: 10,
    //   children: [],
    // },
    // {
    //   id: 194,
    //   pid: 176,
    //   title: '教师管理',
    //   icon: '',
    //   perms: '',
    //   path: '/operation/teacherList',
    //   menuType: 'C',
    //   sort: 11,
    //   children: [],
    // },
    // {
    //   id: 194,
    //   pid: 176,
    //   title: '管理权限',
    //   icon: '',
    //   perms: '',
    //   path: '/operation/roleManager',
    //   menuType: 'C',
    //   sort: 0,
    //   children: [
    //     {
    //       id: 218,
    //       pid: 194,
    //       title: '角色管理',
    //       icon: '',
    //       perms: '',
    //       path: '/operation/roleManager/identityManager',
    //       menuType: 'C',
    //       sort: 10,
    //       children: [],
    //     },
    //     {
    //       id: 219,
    //       pid: 194,
    //       title: '管理员列表',
    //       icon: '',
    //       perms: 'merchant:admin:list',
    //       path: '/operation/roleManager/adminList',
    //       menuType: 'C',
    //       sort: 0,
    //       children: [],
    //     },
    //   ],
    // },
    ]
  }, {
    id: 178,
    pid: 0,
    title: '维护',
    icon: 's-help',
    perms: '',
    path: '/maintain',
    menuType: 'M',
    sort: 999,
    children: [{
      id: 198,
      pid: 178,
      title: '敏感操作日志',
      icon: '',
      perms: '',
      path: '/maintain/sensitiveLog',
      menuType: 'C',
      sort: 10,
      children: []
    }]
  }]
};
export default menu;