//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'UploadFiles',
  props: {
    value: {}
  },
  data: function data() {
    return {
      newFile: '',
      files: []
    };
  },
  watch: {
    value: function value(val) {
      this.files = val;
    }
  },
  methods: {
    onNewFile: function onNewFile(val) {
      this.files.push(val);
      this.$emit('input', this.files);
      this.newFile = '';
    },
    deleteFile: function deleteFile(itemFile, index) {
      this.files.splice(index, 1);
      this.$emit('input', this.files);
    }
  }
};