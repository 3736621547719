var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "file-list" },
        _vm._l(_vm.files, function (itemFile, index) {
          return _c("div", { staticClass: "item-file" }, [
            _c(
              "a",
              {
                staticClass: "name",
                attrs: { href: itemFile.url, target: "_blank" },
              },
              [_vm._v(_vm._s(itemFile.fileName))]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "option",
                on: {
                  click: function ($event) {
                    return _vm.deleteFile(itemFile, index)
                  },
                },
              },
              [_c("i", { staticClass: "el-icon-close" })]
            ),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("uploadFile", {
        attrs: { value: _vm.newFile, "disable-preview": true },
        on: { input: _vm.onNewFile },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }